body .view .inst {
  display: flex;
  align-items: center;
  font-size: 0;
}
body .view .inst__name {
  display: inline-block;
  font-size: 12px;
  width: 30px;
  margin: 0;
}
body .view .inst__steps {
  display: inline-flex;
  justify-content: stretch;
  width: calc(100vw - 60px);
  background-color: #eee;
}
body .view .inst__steps .step {
  width: 3.125%;
  height: 20px;
  border: 1px solid #fff;
  background-color: #eee;
}
body .view .inst__steps .step.now {
  background-color: #aaa;
}
body .view .inst__steps .step.active {
  background-color: #f99;
}
body .view .inst__steps .step.active.now {
  background-color: #a99;
}
body .view .inst__steps .step.accent {
  background-color: #f00;
}
body .view .inst__steps .step.accent.now {
  background-color: #a00;
}
body .form {
  margin: 50px 0 30px;
}
body .form label {
  display: inline-block;
  width: 50px;
  font-size: 12px;
}
body .form input {
  width: calc(100vw - 100px);
  max-width: 400px;
}
/*# sourceMappingURL=index.7fa2ff67.css.map */
