body {
  .view {
    .inst {
      display: flex;
      align-items: center;
      font-size: 0;

      &__name {
        display: inline-block;
        font-size: 12px;
        width: 30px;
        margin: 0;
      }

      &__steps {
        display: inline-flex;
        justify-content: stretch;
        width: calc(100vw - 60px);
        background-color: #eee;

        .step {
          width: calc(100% / 32);
          height: 20px;
          border: 1px solid #fff;
          background-color: #eee;

          &.now { background-color: #aaa; }
          &.active { background-color: #f99; &.now { background-color: #a99; } }
          &.accent { background-color: #f00; &.now { background-color: #a00; } }
        }
      }
    }
  }

  .form {
    margin: 50px 0 30px;
    
    label {
      display: inline-block;
      width: 50px;
      font-size: 12px;
    }

    input {
      width: calc(100vw - 100px);
      max-width: 400px;
    }
  }
}
